<template>
  <div>
    <header>
      <div class="logo-container">
        <h4 class="logo">My Domain</h4>
        <img src="./assets/img/triangle.png" alt="logo" />
      </div>
      <nav>
        <ul class="nav-links">
          <li><a class="nav-link" href="#">Contact Us</a></li>
          <li><a class="nav-link" href="#">Inovacion</a></li>
          <li id="menuOpener" class="nav-mobile-trigger">
            <div class="menu-btn" @click="showMenu = true">
              <div class="btn-line"></div>
              <div class="btn-line"></div>
              <div class="btn-line"></div>
            </div>
          </li>
        </ul>
      </nav>
    </header>

    <div class="nav-mobile" id="nav" v-if="showMenu">
      <ul>
        <li><a class="nav-link" href="#">Contact Us</a></li>
        <li><a class="nav-link" href="#">Inovacion</a></li>
        <li id="menuCloser" @click="showMenu = false">Close</li>
      </ul>
    </div>

    <main>
      <div class="introduction">
        <h1>
          Create your unique business <br />
          domain in minutes
        </h1>
        <h2>to satisfy all your clients needs and requirements</h2>
        <p>
          Personalised domain for your business type will reinforce your
          position in the market and accomplish all your business goals.
        </p>
      </div>

      <div class="write-domain" id="hide-nav" v-if="!showForm">
        <div class="circle">
          <img src="./assets/img/round.png" />
        </div>
        <input
          class="form"
          type="text"
          name="search"
          placeholder="Enter your domain name here..."
          v-model="domainName"
        />
        <span v-if="showMessage" class="message-span">{{ showMessage }}</span>
        <button @click="lookupdomainName" id="closingButton" class="button">
          Create Domain
        </button>
      </div>

      <div class="submit-info" v-if="showForm">
        <p>Please fill in the following form!</p>
        <input
          class="form"
          type="text"
          name="search"
          v-model="name"
          required
          placeholder="Enter your name here..."
        />
        <input
          class="form"
          type="text"
          name="search"
          v-model="phone"
          required
          placeholder="Enter your phone number here..."
        />
        <input
          class="form"
          type="text"
          name="search"
          v-model="email"
          required
          placeholder="Enter your email here..."
        />
        <span v-if="msg.email">{{ msg.email }}</span>
          <div class="text-danger" v-if="hideText">
          <h1>Your Password must be longer than 6 characters!</h1>
        </div> 
        <div class="new-button">
          <button
            class="button"
            @click.prevent="submitForm()"
            @click="checkPhoneLength()"
          >
            Submit
          </button>
          <button id="goBack" class="button" @click="goBack()">
            Go Back
          </button>
        </div>
      </div>

      <div class="under-btn">
        <h2>
          to satisfy all your <br />
          clients needs and <br />
          requirements
        </h2>
        <p>
          Personalised domain for your <br />
          business type will reinforce your <br />
          position in the market and
          <br />
          accomplish all your business <br />
          goals.
        </p>
      </div>
    </main>

    <footer>
      <p>All rights reserved</p>
      <p>Copyright Inovacion Shpk</p>
    </footer>
  </div>
</template>

<script>
import axios from "axios";
import _ from "lodash";
export default {
  data() {
    return {
      domainName: "",
      showMessage: "",
      showForm: false,
      showMenu: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
      name: "",
      phone: "",
      email: "",
      hideText: false,
      msg: [],
    };
  },
  mounted() {},
  watch: {
    domainName: function() {
      if (this.domainName.length == 0) {
        this.showMessage = "";
      }
    },
    email(value) {
      // binding this to the data value in the email input
      this.email = value;
      this.validateEmail(value);
    },
  },
  methods: {
    lookupdomainName: _.debounce(function() {
      var app = this;
      axios
        .get(`${this.baseUrl}/api/domain-reservation?domain=${this.domainName}`)
        .then(function(response) {
          if (response.data.status != 0) {
            app.showMessage = response.data.message;
          } else {
            app.showForm = true;
            app.hideText = "";
          }
        })
        .catch(function(error) {
          alert(error);
        });
    }, 500),
    submitForm() {
      const payload = {
        name: this.name,
        phone: this.phone,
        email: this.email,
        domain: this.domainName,
      };
      axios
        .post(`${this.baseUrl}/api/domain-reservation`, payload)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    checkPhoneLength() {
      if (this.phone.length < 10) {
        this.hideText = true;
      } else {
        this.hideText = false;
      }
    },
    goBack(){
      this.showForm = false;
      this.domainName = "";
    },
    validateEmail(value) {
      let url = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/ //eslint-disable-line
      if (url.test(value)) {
        this.msg["email"] = "";
      } else {
        this.msg["email"] = "Invalid Email Address";
      }
    },
  },
};
</script>
<style lang="scss"></style>
